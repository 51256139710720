import React, { useRef, useEffect, useState } from 'react';
import Image from 'next/image';
import {
  ChevronLeft,
  ChevronRight,
} from '../../images/index';

function Draggable({
  rootClass = '',
  children,
}) {
  const ourRef = useRef(null);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [startingPoint, setStartingPoint] = useState(0);
  const mouseCoords = useRef({
    startX: 0,
    startY: 0,
    scrollLeft: 0,
    scrollTop: 0,
  });

  const handleDragStart = (e) => {
    if (!ourRef.current) return;
    const slider = ourRef.current.children[0];
    const startX = e.pageX - slider.offsetLeft;
    const startY = e.pageY - slider.offsetTop;
    const { scrollLeft } = slider;
    const { scrollTop } = slider;
    mouseCoords.current = {
      startX, startY, scrollLeft, scrollTop,
    };
    // console.log(mouseCoords.current);
    setIsMouseDown(true);
  };
  const handleDragEnd = () => {
    setIsMouseDown(false);
    if (!ourRef.current) return;
  };
  const handleDrag = (e) => {
    if (!isMouseDown || !ourRef.current) return;
    e.preventDefault();
    const slider = ourRef.current.children[0];
    const x = e.pageX - slider.offsetLeft;
    const y = e.pageY - slider.offsetTop;
    const walkX = (x - mouseCoords.current.startX) * 2;
    const walkY = (y - mouseCoords.current.startY) * 2;
    slider.scrollLeft = mouseCoords.current.scrollLeft - walkX;
    slider.scrollTop = mouseCoords.current.scrollTop - walkY;
  };

  return (
    <div ref={ourRef} onMouseDown={handleDragStart} onMouseUp={handleDragEnd} onMouseMove={handleDrag} onMouseLeave={handleDragEnd} className={`${rootClass}`}>
      {children}
    </div>
  );
}

function Carousel({
  id,
  className,
  items,
}) {
  const journalRef = useRef(null);

  const [elementWindow, setElementWindow] = useState((typeof window !== 'undefined' && id) && (document.getElementById(id) || null));

  useEffect(() => {
    setElementWindow((typeof window !== 'undefined' && id) && (document.getElementById(id) || null));
  }, []);

  useEffect(() => {
    const onScroll = () => {
      const maxScrollLeft = elementWindow?.scrollWidth - elementWindow?.clientWidth;
      if (elementWindow.scrollLeft <= 0) {
        // hide left
        document.getElementById(`${id}-control-prev`).style.display = 'none';
        document.getElementById(`${id}-control-next`).style.display = 'flex';
      } else if (elementWindow.scrollLeft >= (maxScrollLeft)) {
        // hide right
        document.getElementById(`${id}-control-next`).style.display = 'none';
        document.getElementById(`${id}-control-prev`).style.display = 'flex';
      } else {
        document.getElementById(`${id}-control-next`).style.display = 'flex';
        document.getElementById(`${id}-control-prev`).style.display = 'flex';
      }
    };
      // clean up code
    elementWindow?.removeEventListener('scroll', onScroll);
    elementWindow?.addEventListener('scroll', onScroll, { passive: true });
    return () => elementWindow?.removeEventListener('scroll', onScroll);
  }, [elementWindow, id]);

  const handleScrollLeft = () => {
    const itemWidth = elementWindow.children[0].offsetWidth;
    elementWindow?.scrollTo(
      {
        left: elementWindow?.scrollLeft - itemWidth,
        behavior: 'smooth',
      },
    );
  };

  const handleScrollRight = () => {
    const itemWidth = elementWindow.children[0].offsetWidth;
    elementWindow?.scrollTo(
      {
        left: elementWindow?.scrollLeft + itemWidth,
        behavior: 'smooth',
      },
    );
  };

  return (
    <div className={`relative ${className}`}>
      <div className='custom-carousel-buttons home-sport-carousel'>
        <button
          id={`${id}-control-prev`}
          className='control-arrow control-prev shadow'
          aria-label= {`carousel control-arrow control-prev ${id}`}
          style={{ display: 'none' }}
          onClick={() => handleScrollLeft()}
        >
          <div className='leading-none flex align-center items-center justify-center' style={{ width: 6 }}>
            <Image src={ChevronLeft} alt='' width={6} height={9} placeholder='empty' />
          </div>
        </button>
        <button
          id={`${id}-control-next`}
          className='control-arrow control-next shadow'
          aria-label= {`carousel control-arrow control-next ${id}`}
          onClick={() => handleScrollRight()}
        >
          <div className='leading-none flex align-center items-center justify-center' style={{ width: 6 }}>
            <Image src={ChevronRight} alt='' width={6} height={9} placeholder='empty' />
          </div>
        </button>
      </div>
      <Draggable innerRef={journalRef} rootClass='cursor-grab'>
        <div id={id} className='flex-container hidden-scrollbar'>
          {
            items
          }
        </div>
      </Draggable>
    </div>
  );
}

export default Carousel;
